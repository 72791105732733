.bgImgVas {
    background-image: linear-gradient(#0c76e0, #77776e);
    min-height: calc(100vh - 286px);
}


.bgImgVas .positionCenter {
    display: flex;
    color: white;
    justify-content: center;
    text-align: center;
    padding-top: 5vh;
}

.blocks {
    height: 195px !important;
}

.bgImgVas h1 {
    display: block;
    font-size: 60px;
}

.imgGoogle img {
    width: 150px;
    margin: 8px 2px;
    height: 60px;
    border: 2px solid white;
    border-radius: 15px;
}


.mobiles2 {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.mobiles2 p {
    height: 0;
}

.mobiles2 p:hover {
    color: blue;
}

@media screen and (max-width: 768px) {

    .bgImgVas .positionCenter {
        padding-top: 40px;
    }

    .bgImgVas h1 {
        font-size: 40px;
        text-align: center;
        margin-bottom: 20px
    }
}



@media screen and (min-width: 768px) and (max-width: 990px) {
    .imgGoogle img {
        width: 120px;
        margin: 2px 2px;
        height: 45px;
    }

}

@media screen and (min-width: 991px) and (max-width: 1206px) {
    .imgGoogle img {
        width: 140px;
        margin: 2px 2px;
        height: 45px;
    }
}

@media screen and (max-width: 351px) {
    .imgGoogle img {
        width: 140px;
        margin: 2px 2px;
        height: 45px;
    }
}


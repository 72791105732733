.bodyBg {
    padding-top: 3rem;
    background-image: linear-gradient(#0c76e0, #b2b246);
    color: white;
    min-height: calc(100vh - 286px);
}

.bodyBgBlur {
    padding-top: 3rem;
    filter: blur(8px);
    background-image: linear-gradient(#0c76e0, #b2b246);
    color: white;
    min-height: calc(100vh - 286px);
}


.boxChoise {
    margin: auto;
    text-align: center;
}

.desc {
    font-family: "PT Sans", sans-serif;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    font-weight: normal;
}

.buttons img {
    margin-bottom: 5px;
}

.buttons span {
    font-family: 'Calibri', serif;
    font-size: 22px;
    padding: 5px;
}

.buttons .imageDriver {
    transform: rotate(5deg);
}

.buttons p {
    font-family: 'Cambria', serif;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
}


.buttons {
    margin-top: 2rem ;
}

.buttons .blockS {
    position: relative;
    width: 100%;
    height: 250px;
    font-size: 20px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: white;
    border: 1px solid black;
    border-radius: 15px;
    background-color: rgba(13, 44, 69, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttons .blockS::before, .buttons .blockS::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    transition: .3s ease-in-out;
    border-radius: 15px;
}

.buttons .blockS::before {
    display: block;
    top: 0;
    left: 0;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
}

.buttons .blockS:after {
    right: 0;
    bottom: 0;
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
}

.buttons .blockS:hover::before {
    border-top: 3px solid yellow;
    border-left: 3px solid yellow;
    width: 100%;
    height: 100%;
}
.buttons .blockS:hover::after {
    border-bottom: 3px solid yellow;
    border-right: 3px solid yellow;
    width: 100%;
    height: 100%;
}

.buttons .blockS:hover {
    background-color: rgba(21, 108, 187, 0.79);
    scale: 1.02;
    border: none;
    transition: all .3s;
}

* {
    box-sizing: border-box;
    --bs-navbar-active-color: yellow;
    /*font-family: 'LetterGothic-Regular';*/
}

html {
    overflow-x: hidden;
}

.navbar-nav .nav-link.active {
    background-size: 100% 2px;
}

@font-face {
    font-family: 'font2c2fe6626939a3afdb4922cff0c5c8f7';
    font-display: swap;
}

@font-face {
    font-family: 'LetterGothic-Regular';
    src: local('LetterGothic-Regular');
    font-display: swap;
}

@font-face {
    font-family: 'AlgerianSh TYGRA';
    src: local('AlgerianSh TYGRA'), url(./algerianshtygra.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    src: local('AlgerianSh TYGRA'), url(./Raleway-VariableFont_wght.ttf) format('truetype');
    font-display: swap;
}

:root {
    /* fonts */
    --font-algeriansh-tygra: "AlgerianSh TYGRA";
    --font-raleway: "Raleway";

    /* font sizes */
    --font-size-19xl: 38px;
    --font-size-xl: 20px;

    /* Colors */
    --color-white: #fff;
    --color-gray: #16191c;

    /* Border radiuses */
    --br-3xs: 10px;
    /* fonts */
    /*--font-algeriansh-tygra: "AlgerianSh TYGRA";*/
    /*--font-raleway: Raleway;*/

    /* font sizes */
    /*--font-size-19xl: 38px;*/
    /*--font-size-xl: 20px;*/
    --font-size-lg: 18px;
    --font-size-11xl: 30px;
    --font-size-sm: 14px;

    /* Colors */
    --color-whitesmoke: #ededed;
    /*--color-white: #fff;*/
    --color-yellow: #fef200;
    /*--color-gray: #16191c;*/
    --color-deepskyblue: #1eacd1;

    /* Border radiuses */
    /*--br-3xs: 10px;*/
    --br-xl: 20px;
}


.langselect__control--is-focused, .myselect__control--is-focused {
    box-shadow: none !important;
    z-index: 99999;
}

.langselect__indicator-separator, .myselect__indicator-separator {
    display: none;
}

.langselect__single-value, .myselect__single-value {
    color: white !important;
}

.myselect__control {
    margin: 15px 10px !important;
    font-size: 20px;
    background-color: inherit !important;
    border: none !important;
    cursor: pointer;
}

.langselect__menu, .myselect__menu {
    background-color: #b2a9a9 !important;
    color: #2c2a2a !important;
}

.langselect__control {
    background-color: #565252 !important;
}

.langselect__placeholder {
    color: white !important;
}

.myselect__indicator {
    color: white !important;
}

.preview {
    display: flex;
    flex-wrap: wrap;
    padding: .5rem;
}

.preview-image {
    width: 150px;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    margin-top: .5rem;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.preview-image img {
    width: 150px;
    height: auto;
}

.preview-remove {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
    background: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.preview-info-progress {
    background: #42b983;
    text-align: center;
    transition: width .22s;
}

.displayNone {
    display: none;
}

.forBlockMe .aboutBlock {
    position: relative;
    border: 1px solid transparent;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    padding: 10px 5px 0 5px;
}

.forBlockMe .aboutBlock::before, .forBlockMe .aboutBlock::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    transition: .3s ease-in-out;
    border-radius: 15px;
}

.forBlockMe .aboutBlock::before {
    display: block;
    top: 0;
    left: 0;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
}

.forBlockMe .aboutBlock:after {
    right: 0;
    bottom: 0;
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
}

.forBlockMe .aboutBlock:hover::before {
    border-top: 3px solid yellow;
    border-left: 3px solid yellow;
    width: 100%;
    height: 100%;
}

.forBlockMe .aboutBlock:hover::after {
    border-bottom: 3px solid yellow;
    border-right: 3px solid yellow;
    width: 100%;
    height: 100%;
}

.forBlockMe .aboutBlock:hover {
    background-color: rgba(64, 113, 133, 0.78);
    scale: 1.02;
    border: none;
    color: #ffea00;
    transition: all .3s;
}
.magic-call-1 {
    cursor: pointer;
    position: fixed;
    width: 60px;
    height: 60px;
    right: 32px;
    bottom: 32px;
    z-index: 20;
    text-align: center;
    line-height: 60px;
    font-size: 44px;
    color: #fff;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: .8;
    animation: animate-magic-call-1 1s infinite ease-in-out;
    transform-origin: 50% 50%
}

.magic-call-2 {
    width: 100px;
    height: 100px;
    right: 12px;
    bottom: 12px;
    position: fixed;
    background-color: transparent;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #090;
    opacity: .1;
    animation: animate-magic-call-2 1.2s infinite ease-in-out;
    transition: all .5s;
    transform-origin: 50% 50%;
    z-index: 20
}

@keyframes animate-magic-call-1 {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
        background-size: 80%
    }

    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }

    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }

    30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
        background: #090 no-repeat center center;
        background-size: 80%
    }

    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }

    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }

    100% {
        transform: rotate(0) scale(1) skew(1deg);
        background: #b6a300 no-repeat center center;
        background-size: 80%
    }
}

@keyframes animate-magic-call-2 {
    0% {
        transform: rotate(0) kscale(.5) skew(1deg);
        opacity: .1
    }

    30% {
        transform: rotate(0) scale(.7) skew(1deg);
        opacity: .5
    }

    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .1
    }
}
.fixedPhone {
    /*position: fixed;*/
    /*bottom: 35px;*/
    /*right: 25px;*/
    /*width: 60px;*/
    /*height: 60px;*/
    /*border-radius: 30%;*/
    /*!*border: 2px solid white;*!*/
    /*background: url("../img/Phone-Icon.webp") center no-repeat;*/
    /*background-size: 40px;*/
    /*animation: shake 0.92s cubic-bezier(.36, .07, .19, .97) 1;*/
    /*animation-delay: 5s;*/
    cursor: pointer;
}

.fixedVacancies {
    position: fixed;
    bottom: 35px;
    left: 5px;
    width: 70px;
    height: 40px;
    border-radius: 30%;
    /*border: 2px solid white;*/
    background: url("../img/animation.gif") center no-repeat;
    background-size: 70px;
    /*animation: shake 2s cubic-bezier(.36, .07, .19, .97) 1;*/
    /*animation-delay: 2s;*/
    cursor: pointer;
}



@media screen and (max-width: 768px) {
    .fixedPhone {
        right: 15px;
    }
}

@media screen and (max-width: 768px) {
    .fixedVacancies {
        left: 5px;
        /*top: 120px;*/
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.fixedPhones {
    background-color: rgba(7, 7, 7, 0.93);
    border-radius: 10px;
    border: 2px solid white;
}

.fixedPhones.active {
    background-color: rgba(7, 7, 7, 0.93);
    border-radius: 10px;
    border: 2px solid white;
}

.phones {
    display: flex;
    text-decoration: none;
    padding-right: -30px;
    font-size: 23px;
    /*visibility: hidden;*/
    transition: visibility .4s ease-in-out;
}

.fixedPhones.active .phones {
    visibility: visible;
}

.phones img {
    transform: translateY(-3px);
    margin-right: 5px;
}

.phones p {
    transform: translateY(8px);
}


.fixedPhones.active span:hover {
    color: #ffffff !important;
}

.primaryNavButton.button {
    /*width: 100%;*/
    border: 3px solid white;
    border-radius: 20px;
    background-color: #0F26F0;
}

.primaryNavButton.fullWidth {
    width: 100%;
}

.phoneNumber.ph-num {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.phoneNumber p {
    color: black;
}

.phoneNumber p:first-child:hover {
    opacity: 0.8;
}

.containerWithBorder.blue {
    text-align: center;
    margin-top: 40px;
    font-size: 18px;
    border: 3px solid #4e5def;
    border-radius: 30px 30px;
    background-color: rgba(40, 34, 34, 0.25);
    padding: 20px;
}

.bgImgVas {
    background-image: linear-gradient(#0c76e0, #77776e);
    min-height: calc(100vh - 286px);
    padding-top: 50px;
    padding-bottom: 20px;
}

.buttons button {
    color: white;
    padding: 10px 0;
    width: 80%;
    min-width: 240px;
    font-size: 1.3rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border: 3px solid #f1f1f1;
    border-radius: 20px;
    margin: 5px;
}

.buttons button:hover {
    color: black;
    background-color: rgb(232, 219, 219);
    border-color: rgba(0, 0, 0, 0.7);
}

.buttons button:active {
    transform: translateX(2px) translateY(2px);
}

.text {

    text-align: center;
    color: white;
    margin-bottom: 20px;
}


@media screen and (max-width: 999px) {
    .buttons button {
        min-width: 230px;
    }
}

@media screen and (max-width: 505px) {
    .buttons button {
        min-width: 180px;
    }
}

@media screen and (max-width: 425px) {

    .buttons button {
        color: white;
        padding: 8px 0;
        font-size: 1.2rem;
        min-width: 150px;
    }
}

@media screen and (max-width: 350px) {

    .buttons button {
        min-width: 120px;
    }
}

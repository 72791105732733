.bg {
    background-image: linear-gradient(#0c76e0, #b2b246);
    position: relative;
    color: white;
    font-size: 16px;
    padding-bottom: 60px;
}

.allFor h3 {
    margin: 20px 0;
}

.firstP {
    font-size: 20px;
    padding: 16px 5px 0 5px;
    margin-bottom: 30px;
    border: 3px solid #4e5def;
    border-radius: 30px 30px;
    background-color: rgba(40, 34, 34, 0.25);
    text-align: center;
    align-items: center;
}

.serviceDesc {
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    .firstP {
        margin-bottom: 50px;
    }

}


@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');

body {
    height: 100%;
}
.homeContainer {
    height: 100%;
}
.homeContainerBlur {
    height: 100%;
    filter: blur(8px);
}
.bgImage {
    background-image: url("../img/taksi.webp");
    width: 100vw;
    height: 100vh;
    filter: blur(3px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bgText {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 10px;
    text-align: center;
    border-radius: 30px;
}

.textColor {
    font-family: 'Kurale', sans-serif;
    font-size: 46px;
    font-weight: bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100%;
    background-image: linear-gradient(#0047ff, #fde301);
    text-shadow: 1px 1px 0 rgba(232, 226, 226, 0.07);
}

.bgText h2 {
    font-family: 'Kurale', sans-serif;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100%;
    background-image: linear-gradient(#fde301 40%, #0047ff);
    text-shadow: 1px 1px 0 rgba(232, 226, 226, 0.23);
}

.imgGoogle {
    position: absolute;
    left: 25%;
    top: 57%;
    width: 50%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border: 3px solid #f1f1f1;
    border-radius: 20px;
}

.imgGoogle p {
    font-size: 2rem;
    color: #ffffff;
    margin: 0;
}

.imgGoogle img {
    border: 2px solid white;
    border-radius: 15px;
    margin: 10px;
}

.list {
    padding-top: 5px;
}

.list img {
    position: relative;
    margin: 3px 10px;
    top: -3px;
}

.contacts {
    color: white;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 27%;
    padding: 10px 10px 10px 0;
    font-size: 1.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border: 3px solid #f1f1f1;
    border-radius: 20px;
}

.linkS span {
    font-family: 'Cambria', serif;
    letter-spacing: 2px;
    font-size: 28px;
    font-weight: 600;
    text-shadow: -1px -1px 0 #000000,
    1px -1px 0 #000000,
    -1px 1px 0 #000000,
    1px 1px 0 #000;
    transition: color .3s;
}

.linkS img {
    border: 2px solid rgba(255, 255, 255, 0);
    border-radius: 50%;
}

span:hover {
    color: white;
}


.buttonsToRedirect {
    color: white;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 77%;
    padding: 10px 10px 10px 0;
    font-size: 1.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    border: 3px solid #f1f1f1;
    border-radius: 20px;
}

.buttonsToRedirect button {
    width: 90%;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0.83);
    color: #ffffff;
}

.buttonsToRedirect button:hover {
    background-color: rgba(150, 150, 144, 0.69);
}

.linkS {
    text-decoration: none;
    color: white;
}

.linkS:hover {
    text-decoration: #ffffff;
}

@media screen and (max-width: 556px) {

    .bgText {
        width: 70%;
    }

    .imgGoogle {
        padding: 5px;
        left: 15%;
        top: 57%;
        width: 70%;
        border-radius: 20px;

    }

    .imgGoogle img {
        width: 80%;
        text-align: center !important;
    }

    .imgGoogle p {
        font-size: 1.4rem;
    }

    .contacts {
        padding: 0 5px 0 0;
        font-size: 2rem;
        width: 70%;
        top: 30%;
        left: 15%;

    }

    .contacts p {
        margin-bottom: 5px;
    }


    .buttonsToRedirect {
        width: 70%;
        left: 15%;
    }

}

@media screen and (max-width: 351px) {
    .bgText {
        width: 95%;
    }

    .imgGoogle {
        padding: 10px;
        left: 2.5%;
        top: 55%;
        width: 95%;
        border-radius: 15px 15px 10px 9px;

    }

    .imgGoogle p {
        font-size: 1.5rem;
    }

    .contacts {
        padding: 0 5px 0 0;
        font-size: 2rem;
        width: 95%;
        top: 27%;
        left: 2.5%;
    }

    .contacts p {
        margin-bottom: 5px;
    }

    .buttonsToRedirect {
        width: 95%;
        left: 2.5%;
    }

}


@media screen and (max-height: 520px) {
    .contacts {
        padding: 10px 5px 0 0;
        font-size: 1.5rem;
    }
}


@media screen and (min-width: 1260px)  and (min-height: 443px) {

    .buttonsToRedirect button {
        padding: 10px;
    }


    .bgText {
        font-size: 2rem;
    }
}

@media screen and (min-width: 270px) and (max-height: 500px) {
    .bgText {
        display: none;
    }

    .imgGoogle {
        padding: 10px;
        left: 10%;
        top: 5%;
        width: 80%;
        border-radius: 15px 15px 10px 9px;

    }

    .imgGoogle p {
        font-size: 1.5rem;
    }

    .contacts {
        padding: 0 5px 0 0;
        font-size: 1.7rem;
        width: 80%;
        top: 35%;
        left: 10%;
    }

    .contacts p {
        margin-bottom: 5px;
    }

    .buttonsToRedirect {
        top: 70%;
    }
}

@media screen and (min-width: 150px)  and (max-width: 272px) {
    .bgText {
        display: none;
    }

    .imgGoogle {
        width: 90% !important;
        left: 5% !important;
        top: 50%;
        font-size: 20px !important;
    }

    .contacts {
        padding: 0 5px 0 0;
        font-size: 20px;
        width: 90% !important;
        top: 15%;
        left: 5% !important;
    }
}

@media screen and (max-width: 700px) and (max-height: 430px) {

    .imgGoogle p {
        font-size: 1.5rem;
    }

    .contacts {
        padding: 0 5px 0 0;
        font-size: 1.2rem;
        top: 37%;
        left: 10%;
        width: 80%;
    }


    .contacts p {
        margin-bottom: 5px;
    }

    .buttonsToRedirect {
        top: 70%;
    }
}


@media screen and (max-height: 700px) {
    .bgImage {
        height: 130vh;
    }
}


@media screen and (max-width: 265px) and (max-height: 340px) {
    .contacts {
        top: 10%;
    }

    .buttonsToRedirect {
        top: 60%
    }
}

@media screen and (max-height: 360px) {
    .buttonsToRedirect {
        display: none;
    }

    .contacts {
        top: 55%;
    }

    .imgGoogle {
        top: 10%;
    }
}

@media screen and (max-width: 1121px) {
    .imgGoogle img {
        width: 170px;
        margin: 3px;
    }

    .linkS span {
        letter-spacing: 0.5px;
    }
}

@media screen and (max-height: 878px ){
    .imgGoogle img {
        width: 130px;
        margin: 3px;
    }
}

@media screen and (min-width: 351px) and (max-width: 368px){
    .contacts {
        width: 80%;
        left: 10%;
    }
}



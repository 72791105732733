.appStoreChild,
.child {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-xl);
    background-color: var(--color-deepskyblue);
    width: 220px;
    height: 210px;
}
.appStoreChild {
    border-radius: var(--br-3xs);
    background-color: var(--color-white);
    box-shadow: 0 19px 39px rgba(0, 0, 0, 0.29);
    width: 160px;
    height: 40px;
}
.appStore3 {
    position: absolute;
    left: 24px;
    text-transform: capitalize;
    display: inline-block;
    width: 90px;
}
.icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    object-fit: cover;
}
.appStore2 {
    top: 10px;
    left: 34px;
    width: 92px;
    height: 20px;
}
.appStore2,
.b,
.icon1 {
    position: absolute;
}
.icon1 {
    top: 120px;
    left: 68px;
    width: 138.1px;
    height: 50px;
    object-fit: cover;
}
.b {
    top: 52px;
    left: 0;
    display: inline-block;
    width: 205px;
}
.span {
    color: var(--color-yellow);
    font-size: var(--font-size-11xl);
}
.div2,
.div3 {
    position: absolute;
}
.div3 {
    top: 0;
    left: 0;
    line-height: 20px;
    font-family: var(--font-algeriansh-tygra);
    display: inline-block;
    width: 198px;
    font-size: var(--font-size-lg);
}
.div2 {
    top: 10px;
    left: 10px;
    width: 205px;
    height: 100px;
    text-align: left;
    color: var(--color-white);
}
.closeIcon,
.div1 {
    position: absolute;
    top: 32px;
    left: 108px;
    width: 220px;
    height: 210px;
}
.closeIcon {
    top: 0;
    left: 304px;
    width: 24px;
    height: 24px;
    overflow: hidden;
}
.icon2 {
    position: absolute;
    top: 70px;
    left: 0;
    width: 155px;
    height: 170px;
    object-fit: cover;
}
.appStore {
    position: fixed;
    bottom: 120px;
    /* right: 16px; */
    width: 328px;
    height: 230px;
    left: 50%;
    transform: translate(-50%, 0);
}
.link {
    color: black;
}
.googlePlay2 {
    position: absolute;
    top: 2px;
    left: 24px;
    text-transform: capitalize;
    display: inline-block;
    width: 82px;
}
.googlePlayIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    overflow: hidden;
}
.googlePlay1 {
    position: absolute;
    top: 10px;
    left: 27px;
    width: 106px;
    height: 20px;
}
.googlePlay,
.goolePlay {
    position: absolute;
    top: 160px;
    left: 30px;
    width: 160px;
    height: 40px;
}
.googlePlay {
    top: 78px;
    left: 16px;
    width: 328px;
    height: 222px;
}
.div {
    /*position: relative;*/
    background-color: var(--color-whitesmoke);
    /*width: 100%;*/
    /*height: 640px;*/
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-sm);
    color: var(--color-gray);
    font-family: var(--font-raleway);
}
.banner_open{
    animation: bounce-in 1s ease-in;
}
.banner_close{
    animation: bounce-out 1s ease-in;
}
@keyframes bounce-in{
    0%    {opacity: 0;   transform: scale(0.5)!important;}
    100%  {opacity: 1;   transform: scale(1)!important;}
}
@keyframes bounce-out{
    0%    {opacity: 1;   transform: scale(1)!important;}
    100%  {opacity: 0;   transform: scale(0.5)!important;}
}

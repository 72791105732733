@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');


.bgImgVas {
    background-image: linear-gradient(#0c76e0, #77776e);
    min-height: calc(100vh - 286px);
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bgImgVasBlur {
    background-image: linear-gradient(#0c76e0, #77776e);
    min-height: calc(100vh - 286px);
    display: block;
    filter: blur(8px);
    justify-content: center;
    align-items: center;
    text-align: center;
}

.logo {
    position: absolute;
    top: 12%;
    left: 4%;
    width: 35%;
    height: 30%;
    background: url("../img/big_logo.webp") no-repeat;
    background-size: contain;
}

.allWithoutText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.bgImgVas img:nth-child(2) {
    margin: 20px 0;
}

.textAboveImg {
    padding: 20px;
    color: #ffffff;
}

.test2 {
    display: flex;
    flex-direction: column;
}

.textAboveImg h1 {
    font-size: 42px;
    font-family: 'Kurale', sans-serif;
}

.region_block {
    color: white;
    padding: 20px;
    font-family: 'Kurale', sans-serif;
}

.region_title h1 {
    font-size: 36px;
    padding-bottom: 45px;
    font-family: 'Kurale', sans-serif;
}

.region_description {
    font-size: 26px;
    font-family: 'Kurale', sans-serif;
}

.links_for_customer_title {
    font-size: 26px;
    font-family: 'Kurale', sans-serif;
}

.links_for_driver_title {
    font-size: 26px;
    font-family: 'Kurale', sans-serif;
}

.region_services pre {
    font-size: 26px;
    padding-bottom: 5px;
    padding-left: 100px;
    text-align: left;
    font-family: 'Kurale', sans-serif;
}


.belowImage {
    color: white;
    padding: 10px;
    width: 50%;
}

.cashBack {
    color: white;
    font-size: 25px;
}

.belowImage h3 {
    font-size: 36px;
    margin-bottom: 20px;
}

.belowImage p {
    margin-top: 20px;
    font-size: 26px;
}


.imgGoogle {
    display: flex;
    justify-content: center;
}

.imgGoogle a:last-child {
    margin-left: 10px;
}

.last {
    padding-bottom: 20px;
}

.ohNo {
    position: relative;
}

.list {
    padding-top: 5px;
    position: absolute;
    top: 25%;
    left: 0;
    font-size: 26px;
    text-decoration: none;
}

.list a {
    text-decoration: none;
}

.list span:hover {
    color: #fffa00 !important;
}

.list img {
    position: relative;
    margin: 3px 10px;
    top: -3px;
}


@media screen and (max-width: 780px) {

    .region_block {
        color: white;
        padding: 20px;
        font-family: 'Kurale', sans-serif;
    }

    .region_title h1 {
        font-size: 36px;
        padding-bottom: 45px;
        font-family: 'Kurale', sans-serif;
    }

    .region_description {
        font-size: 26px;
        font-family: 'Kurale', sans-serif;
    }

    .region_services pre {
        font-size: 26px;
        padding-bottom: 5px;
        padding-left: 10px;
        text-align: left;
        font-family: 'Kurale', sans-serif;
    }

    .allWithoutText {
        display: block;
    }


    .imgGoogle {
        display: flex;
        justify-content: center;
        margin: 20px 0;
    }

    .textAboveImg {
        text-align: center;
    }

    .belowImage {
        width: 100%;
    }

    .textAboveImg h1 {
        font-size: 32px;
    }

    .belowImage h3 {
        font-size: 32px;
        order: 1;
        margin-bottom: 20px;
    }

    .belowImage p {
        font-size: 20px;
        text-align: start;
    }

    .list {
        font-size: 18px;
    }

    .list img {
        width: 25px;
        height: 25px;
    }
}


@media screen and (max-width: 370px) {

    .region_block {
        color: white;
        padding: 20px;
        font-family: 'Kurale', sans-serif;
    }

    .region_title h1 {
        font-size: 36px;
        padding-bottom: 45px;
        font-family: 'Kurale', sans-serif;
    }

    .region_description {
        font-size: 26px;
        font-family: 'Kurale', sans-serif;
    }

    .region_services pre {
        font-size: 26px;
        padding-bottom: 5px;
        padding-left: 10px;
        text-align: left;
        font-family: 'Kurale', sans-serif;
    }

    .list {
        font-size: 16px;
    }

    .list img {
        width: 20px;
        height: 20px;
    }
}




.appStoreChild,
.child {
    position: absolute;
    top: 0;
    left: 0;
}
.child {
    border-radius: 30px;
    background-color: #1eacd1;
    width: 450px;
    height: 420px;
}
.appStoreChild {
    border-radius: var(--br-3xs);
    background-color: var(--color-white);
    box-shadow: 0 19px 39px rgba(0, 0, 0, 0.29);
    width: 200px;
    height: 60px;
}
.appStore2 {
    position: absolute;
    top: 4px;
    left: 38px;
    text-transform: capitalize;
    display: inline-block;
    width: 100px;
}
.appStore,
.appStore1,
.icon {
    position: absolute;
    height: 30px;
}
.icon {
    top: 0;
    left: 0;
    width: 30px;
    object-fit: cover;
}
.appStore,
.appStore1 {
    top: 15px;
    left: 31px;
    width: 138px;
}
.appStore {
    top: 0;
    left: 218px;
    width: 200px;
    height: 60px;
}
.googlePlay1 {
    position: absolute;
    top: 4px;
    left: 38px;
    text-transform: capitalize;
    display: inline-block;
    width: 116px;
}
.googlePlay,
.googlePlayIcon {
    position: absolute;
    height: 30px;
}
.googlePlayIcon {
    top: 0;
    left: 0;
    width: 30px;
    overflow: hidden;
    object-fit: cover;
}
.googlePlay {
    top: 15px;
    left: 23px;
    width: 154px;
}
.googlePlayAppStore,
.goolePlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 60px;
}
.googlePlayAppStore {
    top: 340px;
    left: 16px;
    width: 418px;
}
.link {
    color: black;
}
.b,
.icon1 {
    position: absolute;
}
.icon1 {
    top: 252.4px;
    left: 113px;
    width: 243.7px;
    height: 72.1px;
    object-fit: cover;
}
.b {
    top: 115px;
    left: 0;
    display: inline-block;
    width: 426px;
}
.span {
    font-size: var(--font-size-19xl);
    color: var(--color-white);
}
.span1 {
    font-size: 60px;
    color: #fef200!important;
}
.p,
.p1 {
    margin: 0;
}
.p1 {
    font-size: var(--font-size-19xl);
}
.div4 {
    top: 0;
    left: calc(50% - 213px);
    line-height: 44px;
    font-family: var(--font-algeriansh-tygra);
    display: inline-block;
    width: 418px;
    font-size: 40px;
}
.div2,
.div3,
.div4 {
    position: absolute;
}
.div3 {
    top: 20px;
    left: 16px;
    width: 426px;
    height: 217px;
    text-align: left;
    font-size: 29px;
    color: var(--color-white);
}
.div2 {
    top: 48px;
    left: 308px;
    width: 450px;
    height: 420px;
}
.closeIcon {
    top: 0;
    left: 718px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    overflow: hidden;
    object-fit: cover;
}
.closeIcon,
.div1,
.icon2 {
    position: absolute;
}
.icon2 {
    top: 48px;
    left: 0;
    width: 382px;
    height: 420px;
    object-fit: cover;
}
.div1 {
    position: fixed;
    right: 770px;
    bottom: 585px;
}
.div {
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-xl);
    color: var(--color-gray);
    font-family: var(--font-raleway);
}
.banner_open{
    animation: bounce-in 1s ease-in;
}
.banner_close{
    animation: bounce-out 1s ease-in;
}
@keyframes bounce-in{
    0%    {opacity: 0;   transform: scale(0.5)!important;}
    100%  {opacity: 1;   transform: scale(1)!important;}
}
@keyframes bounce-out{
    0%    {opacity: 1;   transform: scale(1)!important;}
    100%  {opacity: 0;   transform: scale(0.5)!important;}
}